import React from "react";
import { OfferListItem } from "./offerListItem";
import useSWR from "swr";
import { fetcher } from "../../utils/misc";
import { PAST } from "../../utils/endpoints";

export const PastPreviewList = () => {
  const { data, error } = useSWR(`${PAST}?limit=6`, fetcher, {
    revalidateOnFocus: false,
  });

  return !error && data ? (
    <div id="past-preview-list" className="section bg-fel-purple">
      <div className="section-container">
        <h2 className="section-title text-white">Ce que vous avez loupé</h2>
        <div className="section-list">
          {data.data.map((offer, index) => (
            <OfferListItem key={index} offer={offer} invert={true} />
          ))}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
