import React from "react";
import { OfferListItem } from "./offerListItem";
import useSWR from "swr";
import { UPCOMING } from "../../utils/endpoints";
import { fetcher } from "../../utils/misc";

export const UpcomingPreviewList = () => {
  const { data, error } = useSWR(`${UPCOMING}?limit=6`, fetcher, {
    revalidateOnFocus: false,
  });

  return !error && data ? (
    <div className="section fel-blue-gradient">
      <div className="section-container">
        <h2 className="section-title text-fel-purple">
          Bientôt disponibles
        </h2>
        <div className="section-list">
          {data.data.map((offer, index) => (
            <OfferListItem key={index} offer={offer} />
          ))}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
