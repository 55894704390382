export const OfferListItemSkeleton = () => (
  <div className="animate-pulse">
    <div className="relative group-hover:opacity-50">
      <div className="h-72 bg-gray-200" />
    </div>
    <div className="mt-4 space-y-2">
      <div className="mr-6 h-4 w-full rounded bg-gray-200" />
      <h3 className="h-8 w-56 rounded bg-gray-200" />
      <div className="space-y-2">
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-2 h-4 rounded bg-slate-200" />
          <div className="col-span-1 h-4 rounded bg-slate-200" />
        </div>
        <div className="h-4 rounded bg-slate-200" />
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-1 h-4 rounded bg-slate-200" />
          <div className="col-span-2 h-4 rounded bg-slate-200" />
        </div>
      </div>
    </div>
  </div>
);
