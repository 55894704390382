import React from "react";
import Image from "next/image";
import { classNames } from "../../utils/misc";
import ConsultButton from "../../public/images/icon-consult.svg";
import OfferListItemActive from "./OfferListItemActive";

export const OfferListItem = ({ offer, invert = false }) => {
  let isUpcoming = new Date(offer.publicationStartDate) > Date.now();
  let isPassed = new Date(offer.publicationEndDate) < Date.now();
  return (
    <OfferListItemActive
      isActive={!isUpcoming && !isPassed}
      link={`/offers/show/${offer.id}`}
      className={classNames(
        isPassed && "cursor-default opacity-70",
        isUpcoming && "cursor-default",
        "group block"
      )}
    >
      <div className="relative overflow-hidden bg-white">
        <div
          className={classNames(
            !isPassed &&
              !isUpcoming &&
              "transform transition group-hover:scale-110",
            "aspect-w-25 aspect-h-13"
          )}
        >
          <Image
            src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/offers/${offer.offerImages[0].filename}`}
            alt={offer.label}
            layout="fill"
            className="object-contain"
          />
        </div>
        {isUpcoming && (
          <div className="absolute inset-0 flex items-center justify-center bg-fel-purple bg-opacity-60 text-center text-4xl uppercase text-white">
            Disponible
            <br />
            le{" "}
            {new Date(offer.publicationStartDate).toLocaleString("fr-FR", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })}
          </div>
        )}
        {isPassed && (
          <div className="absolute inset-0 bg-fel-purple bg-opacity-20" />
        )}
      </div>
      <div className="mt-4 space-y-2">
        <div
          className={classNames(
            invert ? "bg-white text-fel-purple" : "bg-fel-purple text-white",
            "px-2 text-sm font-bold"
          )}
        >
          {offer.user.company.name}
        </div>
        <h3
          className={classNames(
            invert ? "text-white" : "text-fel-purple",
            "text-lg font-bold tracking-wider"
          )}
        >
          {offer.label}
        </h3>
        <div className="flex flex-row space-x-3">
          <span
            className={classNames(
              invert
                ? "border-white text-white"
                : "border-fel-purple text-fel-purple",
              "inline-block border px-1 text-sm"
            )}
          >
            {offer.offerCategory.name}
          </span>
          {offer.promotion && (
            <span className="inline-block border border-fel-yellow bg-fel-yellow pl-1 pr-1 text-sm font-bold text-white">
              PROMO
            </span>
          )}
          {offer.equipAgro && (
            <span className="inline-block border border-green-600 bg-green-600 pl-1 pr-1 text-sm font-bold text-white">
              Equi&apos;Agro
            </span>
          )}
        </div>
        <div className="relative pr-10">
          <div
            className={classNames(
              invert ? "text-white " : "text-fel-purple",
              "col-span-2 truncate whitespace-nowrap text-sm"
            )}
            dangerouslySetInnerHTML={{
              __html: offer.resume.replace(/(<([^>]+)>)/gi, ""),
            }}
          />
          <div className="absolute bottom-0 right-0">
            <ConsultButton className={classNames(
                !isPassed && !isUpcoming && "group-hover:stroke-fel-purple",
                "h-7 w-7 stroke-fel-yellow stroke-[2px]"
            )} />
          </div>
        </div>
      </div>
    </OfferListItemActive>
  );
};
