import React from "react";
import Link from "next/link";
import Image from "next/image";

export default function WhatsThis() {
  return (
    <section
      id="whats-this"
      className="section -mt-14 -skew-y-3 bg-fel-yellow pb-16"
    >
      <div className="section-container flex skew-y-3 flex-col items-center">
        <div className="relative mb-2.5 h-36 w-44">
          <Link href="/">
            <a>
              <Image
                src="/logo-purple.svg"
                alt="Foire en ligne"
                layout="fill"
                objectFit="contain"
              />
            </a>
          </Link>
        </div>
        <h2 className="section-title mb-6 text-center text-white">
          C&apos;est quoi ?
        </h2>
        <div className="lg:max-w-md xl:max-w-lg">
          <p className="mb-4 text-center text-sm text-fel-purple">
            Bienvenue sur la plateforme Foire en ligne by Foire de Chalons!<br/>
            Un espace entièrement d&eacute;di&eacute; aux offres des exposants de la Foire de Chalons.<br/>
            &Eacute;v&eacute;nements, nouveaut&eacute;s et offres promotionnelles, vous retrouverez toute l&apos;actualit&eacute;s de
            vos marques coup de coeur.<br/>
            Parce que 11 jours de foire c&apos;est trop court, la foire c&apos;est à pr&eacute;sent toute l&apos;ann&eacute;e en ligne !<br/>
            Pour &ecirc;tre au courant des dernières offres, abonnez vous &agrave; la newsletter.<br/>
          </p>
          <h2 className="section-title text-right text-3xl">
            <Link href="/pages/a-propos">
              <a className="text-white hover:text-fel-purple">
                En savoir plus &gt;
              </a>
            </Link>
          </h2>
        </div>
      </div>
    </section>
  );
}
