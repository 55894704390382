import React from "react";
import SubmitOfferIcon from "../../public/images/icon-submit-offer.svg";
import Link from "next/link";

export default function SubmitOffer() {
  return (
    <section className="section mt-4 -skew-y-3 bg-white pb-16">
      <div className="section-container flex skew-y-3 flex-col items-center">
        <SubmitOfferIcon className="mb-2.5 w-20 fill-fel-purple" />
        <h2 className="section-title mb-6 text-center text-fel-purple">
          Vous &ecirc;tes exposant Foire de Chalons ?
        </h2>
        <div className="text-center lg:max-w-md xl:max-w-lg">
          <p className="mb-4 text-sm text-fel-purple">
            Vous souhaitez faire partie de l&apos;aventure, cr&eacute;er votre profil et cr&eacute;er vos offres
            avec les photos de vos produits, pour s&eacute;duire les visiteurs de la Foire de Chalons et multiplier vos contacts !
          </p>
          <h2 className="section-title text-3xl">
            <Link href="/become_exhibitor" as="/espace-exposants">
              <a className="text-fel-yellow hover:text-fel-purple">
                En savoir plus &gt;
              </a>
            </Link>
          </h2>
        </div>
      </div>
    </section>
  );
}
