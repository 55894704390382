import Link from "next/link";
import React from "react";

export default function OfferListItemActive({
  children,
  isActive,
  link,
  ...props
}) {
  return isActive ? (
    <Link href={link}>
      <a {...props}>{children}</a>
    </Link>
  ) : (
    <div {...props}>{children}</div>
  );
}
